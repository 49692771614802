import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, ReplaySubject } from 'rxjs';
import { map, shareReplay, startWith, tap } from 'rxjs/operators';
import { ProfileInfo } from '../../modules/profile/models/profile.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SigninService } from '../../authorization/services/signin.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly USER_DATA_KEY = 'pi';
  //private readonly USER_DATA_KEY = 'Token';
  private apiUrl = environment.apiUrl;

  private _userInfo$ = new ReplaySubject<ProfileInfo | null>();
  userInfo$ = this._userInfo$.pipe(startWith(null), shareReplay(1));
  public errorResponseMessageFromRequest = new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private signInService: SigninService) {
    if (this.signInService.loginStatus()) {
      this.getUserData().subscribe();
    }
  }

  public setUser(user: ProfileInfo) {
    this._userInfo$.next(user);
    localStorage.setItem(this.USER_DATA_KEY, JSON.stringify(user));
  }

  public logOut() {
    this._userInfo$.next(null);
    localStorage.removeItem("Token");
    localStorage.removeItem("pi");
    document.location.href = '/';
  }

  public getUserData(refreshData = false) {
    const fromLocalStorage = localStorage.getItem(this.USER_DATA_KEY);
    if (fromLocalStorage && !refreshData) {
      return of(JSON.parse(fromLocalStorage)).pipe(
        tap((data) => this.setUser(data))
      );
    }
    const mUrl = `${this.apiUrl}account/profile`;
    return this.http
      .get(mUrl)
      .pipe(tap((data) => {
        this.setUser(data as ProfileInfo)
      }));
  }
}
