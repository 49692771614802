import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ShellComponent } from '../shell/shell.component';
import {
  BuyInsuranceSuccessComponent
} from '../modules/profile/components/dash/order-flow/buy-insurance-success/buy-insurance-success.component';
import {
  BuySubscriptionSuccessComponent
} from '../modules/profile/components/dash/order-flow/buy-subscription-success/buy-subscription-success.component';

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      {
        path: 'authorize',
        loadChildren: () =>
          import('../authorization/authorization.module').then(
            (m) => m.AuthorizationModule
          ),
      },
      {
        path: 'buy-subscription-success',
        component: BuySubscriptionSuccessComponent
      },
      {
        path: '',
        component: ShellComponent,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../pages/pages.module').then((m) => m.PagesModule),
          },
          {
            path: 'Dashboard',
            loadChildren: () =>
              import('../modules/profile/profile.module').then(
                (m) => m.ProfileModule
              ),
          },
          {
            path: 'profile',
            loadChildren: () =>
              import('../modules/profile/profile.module').then(
                (m) => m.ProfileModule
              ),
          },
          {
            path: 'customer',
            loadChildren: () =>
              import('../modules/user-profile/user-profile.module').then(
                (m) => m.UserProfileModule
              ),
          },
          {
            path: 'terms',
            loadChildren: () =>
              import('../modules/terms/terms.module').then(
                (m) => m.TermsModule
              ),
          },
          {
            path: 'orderstatus',
            loadChildren: () =>
              import('../modules/orderstatus/orderstatus.module').then(
                (m) => m.OrderstatusModule
              ),
          },
        ],
      },
      { path: '**', component: PageNotFoundComponent },
    ]),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
