import { OverlayModule } from '@angular/cdk/overlay';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterModule } from '@caruspace/components';
import { PayzeService } from '@caruspace/payments';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../../src/environments/environment';
import { AuthInterceptor } from '../app/core/interceptors/auth.interceptor';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { CommonInterceptor } from './core/interceptors/common.interceptor';
import { AccountToggleComponent } from './shell/header/account-toggle/account-toggle.component';
import { HeaderPhoneComponent } from './shell/header/header-phone/header-phone.component';
import { HeaderComponent } from './shell/header/header.component';
import { ShellComponent } from './shell/shell.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UserBlockedComponent } from './shared/features/modals/user-blocked/user-blocked.component';
import { CarBlockedComponent } from './shared/features/modals/car-blocked/car-blocked.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    HeaderComponent,
    HeaderPhoneComponent,
    AccountToggleComponent,
    UserBlockedComponent,
    CarBlockedComponent,
  ],
  imports: [
    FooterModule,
    OverlayModule,
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ka',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    PayzeService,
    { provide: 'API_URL', useValue: environment.apiUrl }
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule { }
