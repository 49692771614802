<!-- <ng-container *ngIf="paymentWithCardData$ | async as paydWithCard">

  Hello {{paydWithCard | json}}
</ng-container> -->


<div class="hood-actions">
  <button class="btn btn--white-filled size-m"
          (click)="goToInvoices()"><img src="assets/images/svg/close.svg"
         alt="close"></button>
</div>

<div class="payment-wrapper custom-material">
  <!-- Pay with saved card -->
  <ng-container *ngIf="cards && !paymentData">
    <mat-form-field appearance="fill"
                    class="full-width">

      <span matPrefix
            *ngIf="selectedCard.value"
            class="prefix"> <img class="type-img"
             src="assets/images/svg/{{selectedCard.value.cardBrand}}.svg"
             alt="images"></span>

      <mat-label>Visa/MC Cards</mat-label>
      <mat-select [formControl]="selectedCard"
                  placeholder="Please select payment option"
                  [ngClass]="'card-select-main'"
                  [panelClass]="'card-select'">
        <mat-option *ngFor="let card of cards.paymentCards"
                    [value]="card">
          <div class="option">
            <img class="type-img"
                 src="assets/images/svg/{{card.cardBrand}}.svg"
                 alt="images">
            <div class="pan">
              <span>{{card.cardNumber | slice:0:4}}</span>
              <span>****</span>
              <span>****</span>
              <span>{{card.cardNumber | slice:15}}</span>
            </div>
          </div>

        </mat-option>

        <mat-option>
          <button type="button"
                  class="add-new-card"
                  routerLink="/customer/credit-cards">
            <img src="assets/images/svg/plus.svg"
                 alt="add">
            Add a new card</button>

        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>


  <ng-container *ngIf="paymentData || paymentFee">
    <!-- xxl {{paymentData | json}} -->
    <div class="payze-container">
      <!-- <ng-container *ngIf="!paymentFee"> -->


      <ng-container *ngIf="paymentFee && !selectedCard.value && paymentData?.amount">
        <h2 class="title">For payment fill the fields bellow</h2>
        <!-- PayZe content here -->
        <div id="card">
          <div id="card-info"></div>
        </div>


        <div class="amount-cntr">
          <div class="amount-cntr__row">
            <div>Commision</div>
            <div>
              <span>{{paymentData.feeAmount | number: '1.2-2'}}</span> <span>₾</span>
            </div>
          </div>
          <div class="amount-cntr__row">
            <div>Subtotal price</div>
            <div>
              <span>{{paymentData.amount | number: '1.2-2'}}</span> <span>₾</span>
            </div>
          </div>
          <div class="amount-cntr__row amount-cntr__row--devide">
            <div>Total price</div>
            <div>
              <span>{{paymentData.totalAmount | number: '1.2-2'}}</span> <span>₾</span>
            </div>
          </div>
        </div>
      </ng-container>


      <ng-container *ngIf="paymentFee && selectedCard.value">
        <div class="amount-cntr">
          <div class="amount-cntr__row">
            <div>Commision</div>
            <div>
              <span>{{paymentFee.feeAmount | number: '1.2-2'}}</span> <span>₾</span>
            </div>
          </div>
          <div class="amount-cntr__row">
            <div>Subtotal price</div>
            <div>
              <span>{{paymentFee.amount | number: '1.2-2'}}</span> <span>₾</span>
            </div>
          </div>
          <div class="amount-cntr__row amount-cntr__row--devide">
            <div>Total price</div>
            <div>
              <span>{{paymentFee.totalAmount | number: '1.2-2'}}</span> <span>₾</span>
            </div>
          </div>
        </div>
      </ng-container>


      <div class="actions">
        <button (click)="submitPayment()"
                class="btn btn__filled btn__bold"
                *ngIf="payze"
                [disabled]="(!validPaymentData && !selectedCard.value)">Pay</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!paymentData && !cards">
    <div class="promo-container">
      <h2 class="promo-container__text">Please select any item/s wich you are agree to pay</h2>
    </div>
  </ng-container>
</div>
