<div>
  <div class="img-cont">
    <img src="../../../../../assets/images/svg/car-blocked1.svg">
  </div>
  <div class="text-cont">
    <p class="header-text">
      მანქანა დაბლოკილია
    </p>
    <p class="mid-text">
      ეს მომხმარებელი ვერ ისარგებლებს ჩვენი კომპანიით.
    </p>
  </div>
  <div class="close-btn-cont">
    <div (click)="close()" class="close-btn">
      დახურვა
    </div>
  </div>
</div>
