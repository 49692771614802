import {
  animation,
  style,
  animate,
  trigger,
  transition,
  useAnimation,
  state,
} from '@angular/animations';

export const collapseAnimation = trigger('collapseAnimation', [
  transition(':enter', [
    style({
      height: 0,
      opacity: 0,
      'padding-top': 0,
      'padding-bottom': 0,
      overflow: 'hidden',
    }),
    animate(
      '0.25s ease-in',
      style({
        height: '*',
        opacity: 1,
        'padding-top': '*',
        'padding-bottom': '*',
      })
    ),
  ]),
  transition(':leave', [
    style({ height: '*', opacity: 1, overflow: 'hidden' }),
    animate(
      '0.2s linear',
      style({ height: 0, opacity: 0, 'padding-top': 0, 'padding-bottom': 0 })
    ),
  ]),
]);
