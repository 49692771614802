<div class="header-container">
  <div class="data-container">
    <div class="header-hood">
      <span>{{ 'NAV.MENU' | translate }}</span>
      <span (click)="onClose.emit()"><img src="../assets/images/svg/cross.svg"
             alt="close" /></span>
    </div>
    <div class="mid-and-low">
      <div class="navigation"
           (click)="onClose.emit()">
        <a href="#">{{ 'NAV.HOME' | translate }}</a>
        <a href="#why">{{ 'NAV.WHY_CARU' | translate }}</a>
        <a href="#reviews">{{ 'NAV.RECOMMENDATIONS' | translate }}</a>
        <a href="#faq">{{ 'NAV.FAQ' | translate }}</a>
        <a href="#contact">{{ 'NAV.CONTACT' | translate }}</a>
      </div>
      <div class="footer">
        <img src="../assets/images/logo.svg"
             alt="Caru logo" />
<!--        <div class="lang-menu"-->
<!--             cdkOverlayOrigin-->
<!--             #originOverlay="cdkOverlayOrigin"-->
<!--             (click)="toggleLangMenu()">-->
<!--          <div class="lang-menu__chosen">-->
<!--            <span class="lang-menu__text">{{ currentLangName }}</span>-->
<!--            <span class="lang-menu__arrow">-->
<!--              &lt;!&ndash; <img src="../assets/images/arrow-down.svg" alt="arrow" /> &ndash;&gt;-->
<!--              <svg width="7"-->
<!--                   height="4"-->
<!--                   viewBox="0 0 7 4"-->
<!--                   fill="none"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M1 1L3.5 3L6 1"-->
<!--                      stroke="#F4F4F4"-->
<!--                      stroke-width="1.5"-->
<!--                      stroke-linecap="round"-->
<!--                      stroke-linejoin="round" />-->
<!--              </svg>-->
<!--            </span>-->
<!--          </div>-->
<!--          <ng-template cdkConnectedOverlay-->
<!--                       [cdkConnectedOverlayOrigin]="originOverlay"-->
<!--                       [cdkConnectedOverlayOpen]="(langMenuShown$ | async)!"-->
<!--                       cdkConnectedOverlayHasBackdrop>-->
<!--            <div class="lang-modal">-->
<!--              <ul class="lang-context">-->
<!--                <li class="lang-context__item"-->
<!--                    [@collapseAnimation]-->
<!--                    *ngFor="let lang of languages"-->
<!--                    (click)="toggleLangMenu()">-->
<!--                  <a (click)="onLangSwitch.emit(lang.lang)">{{ lang.name }}</a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>
