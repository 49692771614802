import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HeaderMenuService {
  private phoneMenuOpenSubject$ = new BehaviorSubject(false);
  public phoneMenuOpen$ = this.phoneMenuOpenSubject$.pipe();

  public toggle() {
    const value = !this.phoneMenuOpenSubject$.value;
    this.phoneMenuOpenSubject$.next(value);
    if (value) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }
  }
}
