<header>
  <nav class="navbar">
    <div class="data-container">
      <div class="navbar__content">
        <section class="navbar__item hamburger-and-logo">
          <div class="hamburger"
               (click)="togglePhoneMenu()">
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div class="logo">
            <a  href="" *ngIf="(accShown$ | async) === false" class="w-100 h-100 d-flex align-items-center justify-content-center">
              <img src="../assets/images/svg/logo-new.svg"
                   alt="Caru logo" />
            </a>

            <a  href="/profile" *ngIf="(accShown$ | async)" class="w-100 h-100 d-flex align-items-center justify-content-center">
              <img src="../assets/images/svg/logo-new.svg"
                   alt="Caru logo" />
            </a>

<!--            <a *ngIf="page === 'main'" href="" class="w-100 h-100 d-flex align-items-center justify-content-center">-->
<!--              <img src="../assets/images/logo.svg"-->
<!--                   alt="Caru logo" />-->
<!--            </a>-->
<!--            <a *ngIf="page === 'profile'" href="/" class="w-100 h-100 d-flex align-items-center justify-content-center">-->
<!--              <img src="../assets/images/logo.svg"-->
<!--                   alt="Caru logo" />-->
<!--            </a>-->
<!--            <a *ngIf="page === 'customer'" href="/customer" class="w-100 h-100 d-flex align-items-center justify-content-center">-->
<!--              <img src="../assets/images/logo.svg"-->
<!--                   alt="Caru logo" />-->
<!--            </a>-->
<!--            <a *ngIf="page === 'other-link'" [href]="link" class="w-100 h-100 d-flex align-items-center justify-content-center">-->
<!--              <img src="../assets/images/logo.svg"-->
<!--                   alt="Caru logo" />-->
<!--            </a>-->
          </div>
        </section>

        <section class="navbar__item navigation">
          <ul class="nav-menu">
            <li class="nav-menu__item nav-menu__item--active">
              <a href="">{{ 'NAV.HOME' | translate }}</a>
            </li>
            <li class="nav-menu__item">
              <a href="#why">{{ 'NAV.WHY_CARU' | translate }}</a>
            </li>
            <li class="nav-menu__item">
              <a href="#reviews">{{ 'NAV.RECOMMENDATIONS' | translate }}</a>
            </li>
            <li class="nav-menu__item">
              <a href="#faq">{{ 'NAV.FAQ' | translate }}</a>
            </li>
            <li class="nav-menu__item">
              <a href="#contact">{{ 'NAV.CONTACT' | translate }}</a>
            </li>
          </ul>
        </section>
        <section class="navbar__item">
          <div class="actions">
            <a routerLink="/authorize"
               *ngIf="(accShown$ | async) === false"
               class="btn d-flex">{{ 'NAV.SIGNIN' | translate }}</a>

            <caruspace-menu-toggle *ngIf="accShown$ | async"
                                   (logout)="logout()"
                                   [userFullName]="(userFullName$ | async)!"
                                   [userPicUrl]="(userPicUrl$ | async)!"
                                    ></caruspace-menu-toggle>

<!--            <div class="lang-menu"-->
<!--                 cdkOverlayOrigin-->
<!--                 #originOverlay="cdkOverlayOrigin"-->
<!--                 (click)="toggleLangMenu()">-->
<!--              <div class="lang-menu__chosen">-->
<!--                <span class="lang-menu__icon"><img [src]="getLangIcon()" alt="enu__icon"></span>-->
<!--                <span class="lang-menu__arrow">-->
<!--                  <img src="../assets/images/arrow-down.svg"-->
<!--                       alt="arrow" />-->
<!--                </span>-->
<!--              </div>-->
<!--              <ng-template cdkConnectedOverlay-->
<!--                           [cdkConnectedOverlayOrigin]="originOverlay"-->
<!--                           [cdkConnectedOverlayOpen]="(langMenuShown$ | async)!"-->
<!--                           cdkConnectedOverlayHasBackdrop>-->
<!--                <div class="lang-modal">-->
<!--                  <ul class="lang-context">-->
<!--                    <li class="lang-context__item"-->
<!--                        [@collapseAnimation]-->
<!--                        *ngFor="let lang of languages"-->
<!--                        (click)="toggleLangMenu()">-->
<!--                        <a (click)="switchLang(lang.lang)" [ngClass]="{'active': getLangIcon() === lang.icon}">{{ lang.alias }} <img [src]="lang.icon" alt="lang"></a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </div>-->
          </div>
        </section>
      </div>
    </div>
  </nav>
</header>

<caruspace-header-phone *ngIf="showPhoneHeader$ | async"
                        [currentLangName]="getLangName()!"
                        (onLangSwitch)="switchLang($event)"
                        (onClose)="togglePhoneMenu()"
                        [languages]="languages"
                        [@collapseAnimation]></caruspace-header-phone>
