import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'caruspace-swiper-buttons',
  template: ` <div class="slide-actions">
    <button type="button" (click)="onPrevious.emit()">
      <img [src]="previousButtonSrc" alt="button" /></button
    ><button type="button" (click)="onNext.emit()">
      <img [src]="nextButtonSrc" alt="button" />
    </button>
  </div>`,
  styleUrls: ['./swiper-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperButtonsComponent {
  @Input() previousButtonSrc: string;
  @Input() nextButtonSrc: String;

  @Output() onNext = new EventEmitter<void>();
  @Output() onPrevious = new EventEmitter<void>();
}
