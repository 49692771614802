import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';
import { Payze } from "@payze/payze-js";
import { PaymentResponseData } from '../../models/payment.model';
import { Observable } from 'rxjs';
import { PayzeService } from '../../services/payze.service';
import { PaymentCardsResponse } from '../../models/payment-cards.model';
import { FormControl } from '@angular/forms';
import { PaymentFeeResponse } from '../../models/payment-fee.model';
import { Router } from '@angular/router';
@Component({
  selector: 'caruspace-initial',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialComponent implements OnInit, AfterViewInit {
  selectedCard = new FormControl('');
  paymentData: PaymentResponseData;
  cards: PaymentCardsResponse;
  paymentFee: PaymentFeeResponse;
  paymentData$ = this.paymentService.paymentData$;
  paymentCards$ = this.paymentService.paymentCards$;
  paymentWithCardData$ = this.paymentService.paymentWithCardData$;
  paymentFee$ = this.paymentService.paymentFee$;
  validPaymentData: boolean;
  @Output() goBack = new EventEmitter<void>();
  @Output() initBlank = new EventEmitter<void>();

  @HostBinding('class.payment-container') someField = true;

  constructor(
    private paymentService: PayzeService,
    private cdf: ChangeDetectorRef,
    private router: Router) { }

  payze: any = Payze;

  ngOnInit(): void {
    this.paymentData$.subscribe(paymentInfo => {
      this.paymentData = paymentInfo;
      this.cdf.detectChanges();

      if (paymentInfo !== null) {
        this.payzePaymentInit(paymentInfo.transactionId);
      }
    })


    this.paymentCards$.subscribe(cards => {
      this.cards = cards;



      if (cards !== null) {
        // Preset default card
        this.selectedCard.patchValue(this.cards.paymentCards.find(card => card.isPrimary));

        // Preset selected card for payment service
        this.paymentService.selectedCardId$.next(this.selectedCard.value?.id);
        // this.payzePaymentInit(paymentInfo.transactionId);
      }

      this.cdf.detectChanges();
    })

    this.paymentFee$.subscribe(fee => {
      this.paymentFee = fee;

      this.cdf.detectChanges();


      // if (cards !== null) {

      //   this.selectedCard.patchValue(this.cards.paymentCards.find(card => card.isPrimary)?.id);
      //   this.paymentService.selectedCardId$.next(this.selectedCard.value);
      //   // this.payzePaymentInit(paymentInfo.transactionId);
      // }

    })



    this.paymentWithCardData$.subscribe(paymentResponse => {
      if (!paymentResponse) {
        return;
      }
      const queryParams = { queryParams: { payment_transaction_id: paymentResponse.transactionId } };
      if (paymentResponse.paymentStatus === 'Succeeded') {
        this.router.navigate(['orderstatus/success'], queryParams)
      } else {
        this.router.navigate(['orderstatus/canceled'], queryParams)
      }
    });

    // orderstatus/success?payment_transaction_id=5E871C6D37FE466D9C97836464FFC095

    // this.paymentService.paymentData$.subscribe(paymePlease=>{
    //   console.log('payme one more time', paymePlease);
    //   this.paymentData = paymePlease;
    // });
    // const initTransaction = this.paymentService.initPayment();






  }


  payzePaymentInit(transactionId: string) {

    if (transactionId) {
      const defaultFieldStyle = 'background-color: #ffffff;font-size:14px;color:#95979E;';

      // const payze = Payze('PG6D8F8EEBF2A04522AED7963DB06CFC8D', {
      this.payze = Payze(transactionId, {
        pan: defaultFieldStyle,
        name: defaultFieldStyle,
        date: defaultFieldStyle,
        cvv: defaultFieldStyle,
        iframeHeight: '200' // height of iframe in pixels (default: 200)
      });
      this.payze.renderCardInfo();
      this.payze.validateCardInfo()
        .subscribe((payzeFormIsValid: boolean) => {
          this.validPaymentData = payzeFormIsValid;
          this.cdf.detectChanges();
        })
    }

  }


  // payWithCard(invoiceItems: number[], invoiceId: number, paymentCardId: number) {
  //   // const mUrl = `${this.apiUrl}Payments/pay-with-saved-card`;
  //   const mUrl = `${this.apiUrl}Payments/get-fee`;
  //   this.http.post<PaymentFeeResponse>(mUrl, { invoiceItemIds: invoiceItems, invoiceId })
  //     .subscribe(paymentFee => {
  //       this.paymentFee = paymentFee;

  //       this.paymentFee$.next(paymentFee);
  //     })
  // }







  goToInvoices() {
    // this.paymentCards$.complete();
    this.paymentCards$ = this.paymentService.paymentCards$;
    this.goBack.emit();
  }

  goToBlankPayment() {
    // this.selectedCard.patchValue(null);
    // // this.paymentService.initBlankPayment(invoiceItems: number[], invoiceId: number);
    // this.initBlank.emit();
  }

  submitPayment() {
    if (this.paymentFee && this.selectedCard.value?.id) {
      this.paymentService.payWithSavedCard(this.selectedCard.value?.id);
    } else {
      this.payze.pay();
    }

  }


  ngAfterViewInit() {
    this.cdf.detectChanges();
  }

}
