// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6Ld7BP0dAAAAAFsFd8CRyUBQBHQdyJT6dYSBDP_p',
  },
  //apiUrl: 'http://localhost:44348/v1/',
  //apiUrl: 'https://new-api.caru.ge/v1/',
  apiUrl: 'https://api.caru.arttteo.com/v1/',
  //apiUrlv1: 'https://api.caru.arttteo.com/v1/',
  googleMapsKey: 'AIzaSyBFbwaw84pxhfsY8dxc19migekJ3KNSLw0',
  //apiUrlWithoutV: 'http://localhost:44348/',
  //apiUrlWithoutV: 'https://new-api.caru.ge/',
  apiUrlWithoutV: 'https://api.caru.arttteo.com/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
