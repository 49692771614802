<section class="foot-section">
  <div class="data-container">
    <!-- <footer> -->
    <div class="foot">
      <div class="foot__nav">
        <div class="foot__nav-section foot__nav-section--start">
          <div class="foot__menu">
            <div class="foot__menu-title"
                 id="contact">{{"FOOTER.CONTACT" | translate}}</div>
            <ul class="foot__menu-items">
              <li class="foot__menu-item">
                <a href="tel:+995571551188" class="d-flex align-items-center">
                  <img src="assets/images/socials/phoneicon.svg"
                  alt="Phone" />
                  <span class="ml-2">571551188</span></a>
              </li>
              <li class="foot__menu-item">
                <a href="//m.me/caru.geofficial" class="d-flex align-items-center"
                   target="_blank">
                   <img src="assets/images/socials/messenger.svg"
                   alt="Phone" />
                   <span class="ml-2">m.me/caru.geofficial</span></a>
              </li>
              <li class="foot__menu-item">
                <a href="mailto:info@caru.ge" class="d-flex align-items-center">
                  <img
                   alt="fb" src="assets/images/socials/mail.svg"/>
                   <span class="ml-2">m.me/caru.geofficialinfo@caru.ge</span></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="foot__nav-section foot__nav-section--center"
             [ngClass]="{ hidden: !allSectionsVisibleInResponsive }">
          <div class="foot__menu">
            <div class="foot__menu-title">{{"FOOTER.WORKING" | translate}}</div>
            <ul class="foot__menu-items">
              <li class="foot__menu-item">
                <span>{{"FOOTER.DAYS" | translate}}</span>
              </li>
            </ul>
          </div>

          <!-- <div class="foot__menu">
              <div class="foot__menu-title">Resources</div>
              <ul class="foot__menu-items">
                <li class="foot__menu-item">
                  <a href="#">Knowledge Base</a>
                </li>
                <li class="foot__menu-item">
                  <a href="#">Contact Us</a>
                </li>
                <li class="foot__menu-item">
                  <a href="#">Block</a>
                </li>
                <li class="foot__menu-item">
                  <a href="#">Podcasts</a>
                </li>
              </ul>
            </div> -->
        </div>

        <div class="foot__nav-section foot__nav-section--end"
             [ngClass]="{ hidden: !allSectionsVisibleInResponsive }">
          <div class="foot__menu">
            <div class="foot__menu-title">{{"FOOTER.COMPANY" | translate}}</div>
            <ul class="foot__menu-items">
              <li class="foot__menu-item">
                <a href="#why">{{"FOOTER.WHY" | translate}}</a>
              </li>

              <li class="foot__menu-item">
                <a href="/terms">{{"FOOTER.TC" | translate}}</a>
              </li>

              <li class="foot__menu-item">
                <a href="#">{{"FOOTER.PP" | translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="toggle-sections-button">
        <button (click)="
              allSectionsVisibleInResponsive = !allSectionsVisibleInResponsive
            ">
          {{ allSectionsVisibleInResponsive ? ("FOOTER.LESS" | translate) : ("FOOTER.MORE" | translate)  }}
        </button>
      </div>

      <div class="foot__links">
        <div class="foot__logo">
          <a href="/">
            <img src="assets/images/logo.svg"
                 alt="Caru logo" />
          </a>
        </div>
        <div class="foot__copyright">
          <span>&copy; 2021 Caru.ge</span>
          <span> - All Rights Reserved</span>
        </div>

        <ul class="foot__socials">
          <li class="foot__social-item">
            <a href="https://www.facebook.com/caru.geofficial/"
               target="_blank"><img src="assets/images/socials/fb.svg"
                   alt="fb" /></a>
          </li>
          <li class="foot__social-item">
            <a href="https://www.youtube.com/c/NikasAutoblog"
               target="_blank"><img src="assets/images/socials/yt.svg"
                   alt="yt" /></a>
          </li>
          <li class="foot__social-item">
            <a href="https://www.instagram.com/caru.ge/"
               target="_blank"><img src="assets/images/socials/inst.svg"
                   alt="instagram" /></a>
          </li>
          <li class="foot__social-item">
            <a href="https://www.tiktok.com/@caru.ge?lang=en"
               target="_blank"><img src="assets/images/socials/tt.svg"
                   alt="TikTok" /></a>
          </li>
        </ul>
      </div>
    </div>
    <!-- </footer> -->
  </div>
</section>
