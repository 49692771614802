import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperBase } from '@caruspace/base-classes';

@Component({
  selector: 'caruspace-images-preview',
  templateUrl: './images-preview.component.html',
  styleUrls: ['./images-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagesPreviewComponent
  extends SwiperBase
  implements AfterViewInit
{
  initialIndex: number;
  imagesSources: string[] = [];

  onNext() {
    this.swiper?.swiperRef.slideNext();
    console.log(this.swiper?.swiperRef.activeIndex);
  }

  onPrev() {
    this.swiper?.swiperRef.slidePrev();
  }

  moveToIndex(index: number) {
    if (this.currentIndex === undefined || index === undefined) {
      return;
    }
    while (index !== this.currentIndex) {
      index > this.currentIndex ? this.onNext() : this.onPrev();
    }
  }

  get currentIndex(): number {
    return (this.swiper?.swiperRef.activeIndex as number) || 0;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.initialIndex = data.initialIndex;
    this.imagesSources = data.imagesSources;
  }

  ngAfterViewInit() {
    this.moveToIndex(this.initialIndex);
  }

  calculatePagesPerSlides(width: number): number {
    return 1;
  }

  getImageDimensions(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    const width = imgElement.naturalWidth;
    const height = imgElement.naturalHeight;
    console.log('Image width:', width);
    console.log('Image height:', height);
    const size = document.querySelector('.cdk-overlay-pane') as HTMLDivElement;

    if(size) {
      if(width > height) {
        size.style.width = '50vw'
      }else {
        size.style.width = 'fit-content'
      }
    }
  }
}
