import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'caruspace-buy-subscription-success',
  templateUrl: './buy-subscription-success.component.html',
  styleUrls: ['../buy-insurance-success/buy-insurance-success.component.scss']
})
export class BuySubscriptionSuccessComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToInsuranceHistory() {
    this.router.navigate(['/customer/subscriptions']);
  }

  navigateToProfile() {
    this.router.navigate(['/profile']);
  }


}
