import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SubscriptionDestroyer } from '@caruspace/base-classes';
import { takeUntil } from 'rxjs/operators';
import { AbstractStepService } from './abstract-step.service';

@Component({ template: '' })
export abstract class AbstractStepComponent
  extends SubscriptionDestroyer
  implements OnInit
{
  protected constructor(
    protected detector: ChangeDetectorRef,
    protected service: AbstractStepService<unknown, unknown>
  ) {
    super();
  }

  ngOnInit() {
    this.service.detectChanges$
      .pipe(takeUntil(this.subscriptionKiller$))
      .subscribe(() => this.detector.detectChanges());
  }
}
