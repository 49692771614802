
<div class="main-container">

  <div (click)="navigateToProfile()" class="x">

  </div>
  <div class="check_check"></div>
  <div class="ty">
    {{"TY" | translate}}!
  </div>
  <div class="trxt">
    {{"SUCCESS_SUBSCRIPTION_TEXT" | translate}}
  </div>
  <div (click)="navigateToInsuranceHistory()" class="btn_see">
    {{"SUBSCRIPTION_SEE" | translate}}
  </div>
</div>
