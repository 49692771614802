import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'caruspace-car-blocked',
  templateUrl: './car-blocked.component.html',
  styleUrls: ['./car-blocked.component.scss']
})
export class CarBlockedComponent {

  constructor(private dialogRef: MatDialogRef<CarBlockedComponent>) { }


  close(){
    this.dialogRef.close();
  }

}
