import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { collapseAnimation } from '@caruspace/animations';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'caruspace-header-phone',
  templateUrl: './header-phone.component.html',
  styleUrls: ['./header-phone.component.scss'],
  animations: [collapseAnimation],
})
export class HeaderPhoneComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  @Input() currentLangName: string;
  @Input() languages: any[];

  @Output() onLangSwitch = new EventEmitter<string>();
  @Output() onClose = new EventEmitter<void>();
  langMenuShownSubject$ = new BehaviorSubject<boolean>(false);
  langMenuShown$ = this.langMenuShownSubject$.asObservable();

  accMenuShownSubject$ = new BehaviorSubject<boolean>(false);
  constructor() {}

  ngOnInit(): void {}
  toggleLangMenu() {
    this.langMenuShownSubject$.next(!this.langMenuShownSubject$.value);
  }

  toggleAccMenu() {
    this.accMenuShownSubject$.next(!this.accMenuShownSubject$.value);
  }

  ngAfterViewInit(): void {
    this.overlay.backdropClick.subscribe((_) =>
      this.langMenuShownSubject$.next(false)
    );
  }
}
