<div class="user-profile"
     cdkOverlayOrigin
     #originOverlay="cdkOverlayOrigin"
     (click)="toggle()">
  <div class="user-profile__context">

<!--    <img class="user-profile__pic" src="{{userPicUrl}}" *ngIf="userPicUrl">-->
    <div *ngIf="userPicUrl" class="user-profile__pic" [style.background-image]="'url(' + userPicUrl + ')'"></div>
    <img *ngIf="!userPicUrl" src="./assets/images/experts/blurry-assistant.png" class="user-profile__letter"/>
<!--    <span class="user-profile__text text-center">{{'USER.MYACC' | translate }}</span>-->
<!--    <span class="user-profile__arrow">-->
<!--&lt;!&ndash;      <img src="../assets/images/arrow-down.svg"&ndash;&gt;-->
<!--&lt;!&ndash;           alt="arrow" />&ndash;&gt;-->
<!--    </span>-->
  </div>

  <ng-template cdkConnectedOverlay
               [cdkConnectedOverlayOrigin]="originOverlay"
               [cdkConnectedOverlayOpen]="(shown$ | async)!"
               cdkConnectedOverlayHasBackdrop>
    <div class="acc-modal">
      <ul class="acc-context">
        <li class="acc-context__item"
            [@collapseAnimation]
            (click)="toggle()">
          <a href="/profile">
            {{"USER.MYORDERS" | translate }}
            <!-- My Orders -->
          </a>
        </li>
        <li class="acc-context__item"
            style="border-bottom: 1px solid #E7E7E7;"
            [@collapseAnimation]
            (click)="toggle()">
          <a routerLink="/customer">
            {{"USER.MYPROFILE" | translate}}
            <!-- My Profile -->
          </a>
        </li>
<!--        <li class="acc-context__item" style="border-bottom: 1px solid #E7E7E7;margin-top:16px;">-->
<!--          <div class="choose-lang-txt">აირჩიეთ ენა</div>-->
<!--          <div class="lang-menu"-->
<!--               cdkOverlayOrigin-->
<!--               #originOverlay="cdkOverlayOrigin"-->
<!--               (click)="toggleLangMenu()">-->
<!--            <div class="lang-menu__chosen">-->
<!--              <span class="lang-menu__icon">-->
<!--                <img [src]="getLangIcon()" alt="enu__icon">-->
<!--              </span>-->
<!--              <span class="lang-local">{{getLangLocalized()}}</span>-->
<!--              <span class="lang-menu__arrow">-->

<!--                  <img src="../assets/images/svg/lang-arr-new.svg"-->

<!--                       alt="arrow" />-->
<!--                </span>-->
<!--            </div>-->
<!--            <ng-template cdkConnectedOverlay-->
<!--                         [cdkConnectedOverlayOrigin]="originOverlay"-->
<!--                         [cdkConnectedOverlayOpen]="(langMenuShown$ | async)!"-->
<!--                         (backdropClick)="closeLangMenu()"-->
<!--                         cdkConnectedOverlayHasBackdrop>-->
<!--              <div class="lang-modal">-->
<!--                <ul class="lang-context">-->
<!--                  <li class="lang-context__item"-->
<!--                      [@collapseAnimation]-->
<!--                      *ngFor="let lang of languages"-->
<!--                      (click)="toggleLangMenu()">-->
<!--                    <a (click)="switchLang(lang.lang)" [ngClass]="{'active': getLangIcon() === lang.icon}">{{ lang.alias }} <img [src]="lang.icon" alt="lang"></a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--        </li>-->
        <li class="acc-context__item"
            style="padding-top: 16px;"
            [@collapseAnimation]
            (click)="toggle()">
          <a style=" color: #ED0006"
             (click)="logout.emit()">
            {{"USER.LOGOUT" | translate }}
            <!-- Log Out -->
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
