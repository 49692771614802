import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import { ImagesPreviewComponent } from './images-preview.component';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [ImagesPreviewComponent],
  imports: [
    CommonModule,
    SwiperModule,
    MatDialogModule,
    // TranslateModule.forChild()
  ],
  exports: [ImagesPreviewComponent],
})
export class ImagesPreviewModule { }
