import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';
import { collapseAnimation } from '@caruspace/animations';
import { languages } from '../../../../assets/i18n/languages';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'caruspace-menu-toggle',
  templateUrl: './account-toggle.component.html',
  styleUrls: ['./account-toggle.component.scss'],
  animations: [collapseAnimation],

})
export class AccountToggleComponent implements AfterViewInit, OnDestroy {
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  @Input() userFullName: string;
  @Input() userPicUrl: string;
  @Output() logout = new EventEmitter<void>();
  _shown$ = new BehaviorSubject<boolean>(false);
  shown$ = this._shown$.asObservable();

  langMenuShownSubject$ = new BehaviorSubject<boolean>(false);
  langMenuShown$ = this.langMenuShownSubject$.asObservable();



  constructor(public translate: TranslateService) { }

  ngAfterViewInit(): void {
    this.overlay.backdropClick.subscribe((_) =>{
          this._shown$.next(false)
          this.langMenuShownSubject$.next(false)
      }
    );
  }

  toggleLangMenu() {
    this.langMenuShownSubject$.next(!this.langMenuShownSubject$.value);
  }

  closeLangMenu(){
    this.langMenuShownSubject$.next(false);
  }
  getLangIcon() {
    const curLang = this.translate.currentLang;
    const foundLang = languages.find((langItem) => langItem.lang === curLang);

    return foundLang?.icon;

    // const foundLang = Object.entries(this.languages).find(
    // const foundLang = this.languages.map(
    //   (key, value) => {
    //     console.log(key);
    //     console.log(value);
    //   }
    // );

    // return languages[lang].alias;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    const foundLang = languages.find((langItem) => langItem.lang === lang);
    localStorage.setItem('lang', JSON.stringify(foundLang));
  }


  toggle() {
    this._shown$.next(!this._shown$.value);
  }


  ngOnDestroy() {
    this._shown$.unsubscribe();
    this.overlay.backdropClick.unsubscribe();
  }

  languages = languages;

  getLangLocalized() {
    const lang  = this.translate.currentLang;
    if(lang === 'ka'){
      return 'ქართული';
    }

    else if(lang === 'ru'){
      return 'Русский';
    }

    else{
      return 'English';
    }

  }
}
