import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { InitialComponent } from './components/initial/initial.component';
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    InitialComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        RouterModule,
    ],
  providers: [],
  exports: [
    InitialComponent
  ]
})
export class PayzeModule { }
