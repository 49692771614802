export interface PaymentStatusResponseData {
    id: number;
    paymentId: string;
    amount: number;
    feeAmount: number;
    finalAmount: number;
    invoiceId: number;
    invoiceNumber: string;
    orderId: number;
    orderNumber: string;
    carId: number;
    carRegistrationNumber: string;
    status: string;
    invoiceItems: InvoiceItem[]
}


interface InvoiceItem {
    id: number;
    description: string;
    amount: number
}

