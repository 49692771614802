import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarLoaderComponent } from './car-loader/car-loader.component';

@NgModule({
  declarations: [CarLoaderComponent],
  imports: [CommonModule],
  exports: [CarLoaderComponent],
})
export class LoaderModule {}
