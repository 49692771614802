import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PaymentCardsResponse } from '../models/payment-cards.model';
import { PaymentFeeResponse } from '../models/payment-fee.model';
import { PaymentStatusResponseData } from '../models/payment-status.model';
import { PaymentWithCardResponse } from '../models/payment-witcard.model';
import { PaymentResponseData } from '../models/payment.model';
@Injectable({
  providedIn: 'root'
})
export class PayzeService {


  apiUrl: string;
  paymentFee$: BehaviorSubject<PaymentFeeResponse> = new BehaviorSubject<any>(null);
  paymentFee: PaymentFeeResponse;
  paymentData: PaymentResponseData;
  paymentData$: BehaviorSubject<PaymentResponseData> = new BehaviorSubject<any>(null);
  paymentWithCardData: PaymentWithCardResponse;
  paymentWithCardData$: BehaviorSubject<PaymentWithCardResponse> = new BehaviorSubject<any>(null);
  paymentCards: PaymentCardsResponse;
  paymentCards$: BehaviorSubject<PaymentCardsResponse> = new BehaviorSubject<any>(null);
  selectedCardId$: BehaviorSubject<number> = new BehaviorSubject<any>(null);
  invoiceItems: number[];
  invoiceId: number;


  constructor(private http: HttpClient,
    private router: Router,
    @Inject('API_URL') public API_URL?: string) {
    this.apiUrl = API_URL || '';
  }



  initPayment(invoiceItems: number[], invoiceId: number): void {
    // const mUrl = `${this.apiUrl}Payments/start`;
    this.invoiceItems = invoiceItems;
    this.invoiceId = invoiceId;

    // Retreive cards if user has at least one
    this.getStoredCards()
      .pipe(
        tap((cards) => {
          if (!cards || cards.paymentCards.length === 0) {
            this.initBlankPayment(invoiceItems, invoiceId)
          }
          else {
            this.initFeeCheck(invoiceItems, invoiceId);
            // this.selectedCardId$.subscribe(cardId => {
            //   if (cardId != null) {
            //     this.initFeeCheck(invoiceItems, invoiceId);
            //   }
            // })
          }
        }),
      )
      .subscribe(cards => {
        // this.paymentCards = cards;
        this.paymentCards$.next(cards);
      });
    // .pipe(map(payment => payment as PaymentResponse));
  }


  initBlankPayment(invoiceItems: number[], invoiceId: number) {
    const mUrl = `${this.apiUrl}payments/pay-with-new-card`;
    this.http.post<PaymentResponseData>(mUrl, { invoiceItemIds: invoiceItems, invoiceId })

      .subscribe({
        next: paymentData => {
          this.paymentData = paymentData;
          this.paymentData$.next(paymentData);
          console.log(this.paymentData.transactionUrl)
          // this.router.navigateByUrl(this.paymentData.transactionUrl)
          window.open(this.paymentData.transactionUrl)
        },
        error: error => {
          alert('Something went wrong!!');
          this.router.navigate(['orderstatus/canceled'], {})
          console.error('There was an error!', error);
        }
      })

  }


  initFeeCheck(invoiceItems: number[], invoiceId: number) {
    const mUrl = `${this.apiUrl}payments/get-fee`;
    return this.http.post<PaymentFeeResponse>(mUrl, { invoiceItemIds: invoiceItems, invoiceId })
      .pipe(
        tap(paymentFee => {
          this.paymentFee = paymentFee;
          this.paymentFee$.next(paymentFee);
        })
      )
  }

  /*initWithCardPayment(invoiceItems: number[], invoiceId: number, paymentCardId: number) {
    const mUrl = `${this.apiUrl}Payments/pay-with-saved-card`;
    this.http.post<PaymentWithCardResponse>(mUrl, { invoiceItemIds: invoiceItems, invoiceId, paymentCardId })
      .subscribe(paymentData => {
        this.paymentWithCardData = paymentData;
        this.paymentWithCardData$.next(paymentData);
      })
  }*/

  payWithSavedCard(paymentCardId: number) {
    const mUrl = `${this.apiUrl}payments/pay-with-saved-card`;
    this.http.post<PaymentWithCardResponse>(mUrl, { invoiceItemIds: this.invoiceItems, invoiceId: this.invoiceId, paymentCardId })
      .subscribe(paymentData => {
        this.paymentWithCardData = paymentData;
        this.paymentWithCardData$.next(paymentData);
      })
  }

  getStoredCards() {
    const paymentStatusUrl = `${this.apiUrl}payments/user-payment-cards`;
    return this.http.get<PaymentCardsResponse>(paymentStatusUrl);
  }

  checkTransactionById(transactionId: string) {
    const paymentStatusUrl = `${this.apiUrl}payments/${transactionId}`;
    return this.http.get<PaymentStatusResponseData>(paymentStatusUrl);
  }
}
