<div class="wrapper">
  <div class="header">
    <div class="text">Photos from a working process</div>
    <button class="btn p-0 bg-transparent border-0 close-btn" [mat-dialog-close]="true">
      <img src="assets/images/svg/close.svg"
      alt="close">
    </button>
  </div>
  <div class="swiper swiper-wrapper">
    <swiper #swiper [slidesPerView]="(itemsPerSlide$ | async)!">
      <ng-template swiperSlide *ngFor="let image of imagesSources">
        <img [src]="image" #overlayContainer (load)="getImageDimensions($event)" class="background-image" style="object-fit: contain"/>
      </ng-template>
    </swiper>
  </div>

  <button class="prev" (click)="onPrev()">
    <img src="assets/images/svg/arrow-right.svg" alt="arrow" />
  </button>
  <button class="next" (click)="onNext()">
    <img src="assets/images/svg/arrow-right.svg" alt="arrow" />
  </button>
  <div class="images">
    <img
      [ngClass]="{ chosen: currentIndex === i }"
      [src]="image"
      alt="image"
      *ngFor="let image of imagesSources; let i = index"
      (click)="moveToIndex(i)"
    />
  </div>
</div>
