import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {languages} from "../assets/i18n/languages";

@Component({
  selector: 'caruspace-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(private title: Title, private translateService: TranslateService) {
    this.title.setTitle('Home page - caru.ge');

    this.translateService.addLangs(['ka', 'en', 'ru']);
    const browserLang = translateService.getBrowserLang();
    let defaultLang = 'ka';

    const lsLang = localStorage.getItem('lang');
    if (lsLang) {
      defaultLang = 'ka'



        // JSON.parse(lsLang)?.lang ||
        // languages.find((lang) => lang.lang === defaultLang);
    } else {
      // defaultLang =
      //   browserLang && browserLang.match(/en|ka|ru/)
      //     ? browserLang
      //     : defaultLang;
      localStorage.setItem(
        'lang',
        JSON.stringify(languages.find((lang) => lang.lang === defaultLang))
      );
    }

    translateService.use(defaultLang);
    translateService.setDefaultLang('ka');
    this.translateService.setDefaultLang('ka');

  }
}
