import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const lsLang = JSON.parse(localStorage.getItem('lang') || '{}');
    let language = 'en-US';
    if (Object.prototype.hasOwnProperty.call(lsLang, "iso")) {
      language = lsLang.iso;
    }

    const modifiedRequest = request.clone({
      headers: request.headers.set('accept-language', `${language}`),
    });
    return next.handle(modifiedRequest);
    // return next.handle(request);
  }
}
