import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ViewportScroller, Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { collapseAnimation } from '@caruspace/animations';
import { HeaderMenuService } from '../../shared/global-services/header-menu.service';
import { ProfileInfo } from 'apps/root-app/src/app/modules/profile/models/profile.model';
import { UserService } from '../../shared/global-services/user.service';
import { map, pluck, tap } from 'rxjs/operators';
import { languages } from '../../../assets/i18n/languages';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'caruspace-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [collapseAnimation],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;
  langMenuShownSubject$ = new BehaviorSubject<boolean>(false);
  langMenuShown$ = this.langMenuShownSubject$.asObservable();
  showPhoneHeader$ = this.headerMenu.phoneMenuOpen$;

  accMenuShownSubject$ = new BehaviorSubject<boolean>(false);
  accMenuShown$ = this.accMenuShownSubject$.asObservable();

  userFullName$ = this.userService.userInfo$.pipe(
    map((data) => data?.fullName)
  );

  userPicUrl$ = this.userService.userInfo$.pipe(
    map((data) => data?.profilePicUrl)
  );

  accShown$ = this.userFullName$.pipe(map((d) => !!d));

  page = '';
  link = '';

  languages = languages;

  constructor(
    private vpScroller: ViewportScroller,
    public translate: TranslateService,
    private headerMenu: HeaderMenuService,
    private userService: UserService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    // logic for refreshing when on main and when on other pages - CARU-56
    this.router.events.subscribe((val) => {
      // when navigated
      if (val instanceof NavigationEnd) {
        if (
          val.url === '/' ||
          val.url === '/#why' ||
          val.url === '/#reviews' ||
          val.url === '/#faq' ||
          val.url === '/#contact'
        ) {
          this.page = 'main';
        } else if (val.url === '/profile') {
          this.page = 'profile';
        } else if (val.url === '/customer') {
          this.page = 'customer';
        } else {
          this.page = 'other-link'
          this.link = val.url;
        }
      }
    });
    // when refreshed
    if (
      this.router.url === '/' ||
      this.router.url === '/#why' ||
      this.router.url === '/#reviews' ||
      this.router.url === '/#faq' ||
      this.router.url === '/#contact'
    ) {
      this.page = 'main';
    } else if (this.router.url === '/profile') {
      this.page = 'profile';
    } else if (this.router.url === '/customer') {
      this.page = 'customer';
    } else {
      this.page = 'other-link'
      this.link = this.router.url;
    }
  }

  ngAfterViewInit(): void {
    this.overlay.backdropClick.subscribe((_) =>
      this.langMenuShownSubject$.next(false)
    );
  }

  toggleLangMenu() {
    this.langMenuShownSubject$.next(!this.langMenuShownSubject$.value);
  }

  toggleAccMenu() {
    this.accMenuShownSubject$.next(!this.accMenuShownSubject$.value);
  }

  togglePhoneMenu() {
    this.headerMenu.toggle();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    const foundLang = languages.find((langItem) => langItem.lang === lang);
    localStorage.setItem('lang', JSON.stringify(foundLang));
  }

  getLangIcon() {
    const curLang = this.translate.currentLang;
    const foundLang = languages.find((langItem) => langItem.lang === curLang);

    return foundLang?.icon;

    // const foundLang = Object.entries(this.languages).find(
    // const foundLang = this.languages.map(
    //   (key, value) => {
    //     console.log(key);
    //     console.log(value);
    //   }
    // );

    // return languages[lang].alias;
  }

  getLangName() {
    const curLang = this.translate.currentLang;
    const foundLang = languages.find((langItem) => langItem.lang === curLang);

    return foundLang?.name;
  }

  logout() {
    this.userService.logOut();
  }

  onClickScroll(elementId: string) {
    this.vpScroller.scrollToAnchor(elementId);
  }
}
