<div class="data-container">
  <div class="lost">
    <div class="lost__oops">OOOPS.</div>
    <div class="lost__image">
      <img src="/assets/images/general/404.svg" alt="not found" />
    </div>
    <div class="lost__text">
      Sorry we couldn't find thath page, try to go to <a href="/">Main Page</a>
    </div>
  </div>
</div>
