import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'caruspace-user-blocked',
  templateUrl: './user-blocked.component.html',
  styleUrls: ['./user-blocked.component.scss']
})
export class UserBlockedComponent {

  constructor(private dialogRef: MatDialogRef<UserBlockedComponent>) { }


  close(){
    this.dialogRef.close();
  }


}
