import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IbanWithCopyComponent } from './iban-with-copy/iban-with-copy.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [IbanWithCopyComponent],
  imports: [CommonModule, ClipboardModule],
  exports: [IbanWithCopyComponent],
})
export class IbanWithCopyModule {}
