import { Component, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwiperComponent } from 'swiper/angular';
import { SubscriptionDestroyer } from '..';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class SwiperBase extends SubscriptionDestroyer {
  @ViewChild(SwiperComponent, { static: false }) swiper?: SwiperComponent;
  private innerWidthSubject$ = new BehaviorSubject(window.innerWidth);
  itemsPerSlide$ = this.innerWidthSubject$.pipe(
    map((n) => this.calculatePagesPerSlides(n))
  );
  slideSpeed = 200;
  gap = 16;

  slideNext() {
    this.swiper?.swiperRef.slideNext(this.slideSpeed);
  }

  slidePrev() {
    this.swiper?.swiperRef.slidePrev(this.slideSpeed);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidthSubject$.next(window.innerWidth);
  }

  abstract calculatePagesPerSlides(width: number): number;
}
