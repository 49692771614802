import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class SubscriptionDestroyer implements OnDestroy {
  protected subscriptionKiller$ = new Subject();
  ngOnDestroy(): void {
    this.subscriptionKiller$.next();
    this.subscriptionKiller$.complete();
  }
}
