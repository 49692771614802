import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetPassword } from '../models/reset-password';
import { environment } from './../../../../src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  signIn(userName: string, password: string, isPartu: boolean) {
    const mUrl = `${this.apiUrl}account/login-user`;
    return this.http.post(mUrl, { userName, password, isPartu });
  }

  loginStatus(): boolean {
    const token = JSON.parse(localStorage.getItem('Token') || '{}');
    if (
      Object.prototype.hasOwnProperty.call(token, 'accessToken') &&
      token.accessToken
    ) {
      return true;
    }
    return false;
  }

  recoverPassword(data: ResetPassword) {
    return this.http.post(`${this.apiUrl}account/forgot-password-reset`, data);
  }
}
