import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

export abstract class AbstractStepService<INPUT, OUTPUT> {
  form: FormGroup = new FormGroup({}); // If child service just merges two or more forms together it will not need to have it's dedicated formgroup.
  public detectChanges$ = new Subject();
  autoFilling = false;

  public isValid() {
    return !this.form?.invalid;
  }

  public resetForm(emitEvent?: boolean) {
    this.form?.reset('', { ...(emitEvent && { emitEvent }) });
  }

  abstract readForm(): OUTPUT;

  abstract fillForm(data: INPUT): void;

  // if we want to mark form as touched in order to show invalid fields while navigating to next step if form is invalid.
  public markAllAsTouched() {
    this.form?.markAllAsTouched();
    this.detectChanges$.next();
  }

  disableForm() {
    this.form?.disable();
  }

  getValidFieldsCount(): number {
    let validCount = 0;
    // Iterate through each control in the form
    Object.values(this.form.controls).forEach(control => {
      if (control.valid && control?.validator !== null) {
        validCount++; // Increment valid fields count
      }
    });

    return validCount;
  }

}
