export const   languages = [
  {
    lang: 'en',
    alias: 'English',
    name: 'English',
    iso: 'en-US',
    icon:'../../../assets/images/language-icons/ENG.svg'
  },
  {
    lang: 'ka',
    alias: 'Georgian',
    name: 'ქართული',
    iso: 'ka-GE',
    icon:'../../../assets/images/language-icons/GEO.svg'
  },
  // {
  //   lang: 'ru',
  //   alias: 'Russian',
  //   name: 'Русский',
  //   iso: 'ru-RU',
  //   icon:'../../../assets/images/language-icons/RUS.svg'
  //
  // },
];
